export const hr = {
  MODAL: {
    YES: 'Da, siguran sam',
    NO: 'Ne, zatvori',
    CLOSE: 'Zatvori'
  },
  SIGN_IN: {
    SIGN_IN_HEAD: 'Aplikacija za praćenje profesionalnog puta maturanata',
    EMAIL: 'Email',
    PASSWORD: 'Lozinka',
    SIGN_IN: 'Prijavi se',
    USER_NOT_FOUND: 'Korisnik nije pronađen',
    WRONG_PASSWORD: 'Pogrešna lozinka',
    SOMETHING_WENT_WRONG: 'Nešto nije u redu, pokušajte ponovno'
  },
  SIDE_NAV: {
    RECIPIENTS: 'Ispitanici',
    POLLS: 'Upitnici',
    USERS: 'Korisnici',
    SIGN_OUT: 'Odjavi se',
    LOGOUT_MESSAGE: 'Jeste li sigurni da se želite odjaviti?',
    GRADUATES: 'Maturanti'
  },
  TOP_NAV: {
    ENGLISH: 'Engleski',
    CROATIAN: 'Hrvatski'
  },
  POLLS: {
    POLLS_CANCELLATION: 'Jeste li sigurni da želite odustati od kreiranja upitnika?',
    POLLS_EDIT_CANCELLATION: 'Jeste li sigurni da želite odustati od uređivanja upitnika?',
    POLLS_HEAD: 'Upitnici',
    POLLS_CREATE_NEW: 'Novi upitnik',
    POLLS_EDIT: 'Uredi istraživanje',
    POLL_NAME: 'Naziv',
    PUBLICATION_DATE: 'Datum objave	',
    END_DATE: 'Datum završetka',
    STATUS: 'Status',
    PARTICIPATED: 'Pristupilo',
    NUMBER_OF_RECIPIENTS: 'Poslano ispitanika',
    TOTAL_RECIPIENTS: 'Obuhvaća ispitanika',
    DESCRIPTION: 'Opis',
    INSTRUCTIONS: 'Upute za popunjavanje',
    TARGETED_AUDIENCE: 'Ciljani ispitanici',
    ADD_QUESTION: 'Dodaj pitanje',
    SAVE: 'Spremi',
    QUIT: 'Odustani',
    QUESTION: 'Pitanje',
    ENTER_QUESTION: 'Unesi pitanje',
    ADD_ANSWER: 'Dodaj odgovor',
    ANSWER_TYPE: 'Vrsta odgovora',
    REQUIRED: 'Obavezno',
    ONE_OPTION_CHOICE: 'Izbor jedne opcije',
    MULTIPLE_OPTION_CHOICE: 'Izbor više opcija',
    TEXT_INPUT: 'Tekstualni unos',
    OFFERED_ANSWERS: 'Ponuđeni odgovori:',
    ENTER_ANSWER: 'Unesi odgovor',
    SUCCESSFULLY_CREATED_POLL: 'Uspješno ste kreirali upitnik',
    CREATED_POLL_FAIL: 'Nešto nije u redu, pokušajte ponovno',
    TYPE_IN_ANSWER: 'Unesi odgovor'
  },

  POSITIONS: {
    FINISH_POSITIONS_REQUEST: 'Jeste li sigurni da želite završiti upitnik?',
    EDUCATION: 'Obrazovanje',
    WORK: 'Posao',
    YEARLY_POLL_NAME: 'Godišnji upitnik o obrazovanju i radu',
    GRADUATED: 'Završeno?',
    EDUCATION_PLACEHOLDER: 'Naziv obrazovne ustanove',
    EDUCATION_PLACEHOLDER_2: 'Naziv stupanja obrazovanja',
    WORK_PLACEHOLDER: 'Naziv poslodavca',
    WORK_PLACEHOLDER_2: 'Naziv radnog mjesta',

    UNSUBSCRIBE: 'Želite li se odjaviti od primanja obavijesti o novim upitnicima?',
    FINISHED_MESSAGE_HEADER: 'Upitnik popunjen!',
    FINISHED_MESSAGE: 'Hvala Vam na sudjelovanju u upitniku! Vaši odgovori su zabilježeni.',
    EMPTY_INPUT_FIELD: 'Polje ne smije biti prazno',
    FINISH: 'Pošalji',
    HEADER_MESSAGE:
      'U sklopu internog istraživanja, a s ciljem što realnije procjene stvarne uspješnosti naših programa i metoda, u Tehničkoj školi Sisak želja nam je dugoročno pratiti obrazovanje i profesionalni razvoj naših učenika i nakon što maturiraju ili polože neki od programa za odrasle u našoj školi.\nPozivamo Vas, stoga, da u ovom kratkom upitniku u rubrici "Obrazovanje" navedete naziv obrazovne ustanove u kojoj ste nastavili svoj obrazovni put (moguće je unijeti više stavki), a u rubrici "Posao" navedete naziv radnog mjesta na kojem ste trenutno zaposleni, ako ste zaposleni.',
    ADD: 'Dodaj',
    WARNING_MESSAGE: 'Molimo popunite barem jedno polje'
  },

  POLL_LIST: {
    FINISH_POLL_REQUEST: 'Jeste li sigurni da želite završiti upitnik?',
    PUBLISH_POLL_REQUEST:
      'Jeste li sigurni da želite objaviti upitnik i proslijediti ga na adrese ispitanika?',
    DELETE_POLL_REQUEST: 'Jeste li sigurni da želite obrisati upitnik?',
    FINISH_POLL_SUCCESS: 'Uspješno završen upitnik',
    PUBLISH_POLL_SUCCESS: 'Uspješno objavljen upitnik',
    DELETE_POLL_SUCCESS: 'Uspješno obrisan upitnik',
    EDIT_POLL_SUCCESS: 'Uspješno uređen upitnik',
    FINISH_POLL: 'Zaključi istraživanje',
    DELETE_POLL: 'Obriši upitnik',
    EDIT_POLL: 'Uredi upitnik',
    PUBLISH_POLL: 'Objavi upitnik',
    CLONE_POLL: 'Kloniraj upitnik',
    POLL_PREVIEW: 'Pogledaj rezultate',
    PUBLISHED: 'Objavljeno',
    UNPUBLISHED: 'Neobjavljeno',
    FINISHED: 'Zaključeno',
    IN_PROGRESS: 'Slanje u tijeku'
  },

  GRADUATES: {
    RECIPIENTS_HEAD: 'Ispitanici',
    ADD_NEW: 'Dodaj novog maturanta',
    ADD_NEW_FROM_FILE: 'Dodaj maturante putem datoteke',
    SEARCH: 'Pretraži',
    SEARCH_RECIPIENTS: 'Pretraži maturante',
    SEARCH_TAGS: 'Pretraži oznake',
    SEARCH_POLLS: 'Pretraži upitnike',
    SEARCH_JOB_EDUCATION: 'Pretraži posao i obrazovanje',
    DELETE_MESSAGE: 'Jeste li sigurni da želite obrisati maturanta?',
    QUIT_MESSAGE: 'Jeste li sigurni da želite odustati od kreiranja maturanta?',
    FIRST_NAME: 'Ime',
    LAST_NAME: 'Prezime',
    FIRST_LAST_NAME: 'Ime i prezime',
    DATE_OF_BIRTH: 'Datum rođenja',
    DATE_OF_GRADUATION: 'Godina maturiranja',
    GRADUATE_MAJOR: 'Smjer',
    SELECT_TAGS: 'Odaberi oznake',
    WORK: 'Posao',
    EDUCATION: 'Obrazovanje',
    WORK_AND_EDUCATION: 'Posao/Obrazovanje',
    DELETE: 'Obriši',
    EMAIL: 'Email',
    TAGS: 'Oznake',
    EDIT: 'Uredi',
    CREATE_RECIPIENT: 'Kreiraj novog maturanta',
    CREATE_NEW_TAG: 'Kreiraj novu oznaku',
    NO_RESULT: 'Nema rezultata',
    CREATE: 'Kreiraj',
    QUIT: 'Odustani',
    UPDATE: 'Ažuriraj',
    RESET: 'Resetiraj',
    UPLOAD_FROM_CSV_FILE: 'Učitaj maturante iz CSV datoteke',
    UPLOAD: 'Učitaj',
    UPLOAD_INSTRUCTIONS: 'CSV datoteka mora biti u sljedećem formatu',
    UPLOAD_INSTRUCTIONS_SECOND: 'Preporučeno je da datoteka ne sadrži više od 1000 maturanata',
    ERROR: 'Greška',
    UPLOAD_FROM_FILE_ERROR: 'Sljedeći maturanti nisu uspješno kreirani',
    SUCCESSFULLY_DELETED_RECIPIENT: 'Maturant je uspješno izbrisan',
    SUCCESSFULLY_CREATED_RECIPIENT: 'Maturant je uspješno kreiran',
    SUCCESSFULLY_UPDATED_RECIPIENT: 'Maturant je uspješno ažuriran',
    SUCCESSFULLY_UPLOADED_RECIPIENTS: 'Maturanti su uspješno učitani',
    RECIPIENT_ALREADY_EXIST: 'Maturant s ovom email adresom već postoji',
    RECIPIENT_HAS_SUBMITTED_POLL: 'Maturant ima ispunjen upitnik',
    EMAIL_FORMAT_IS_WRONG: 'Email adresa nije ispravna',
    FIRST_NAME_UNDEFINED: 'Ime nije definirano',
    LAST_NAME_UNDEFINED: 'Prezime nije definirano',
    EMAIL_UNDEFINED: 'Email nije definiran',
    DATE_OF_BIRTH_UNDEFINED: 'Datum rođenja nije definiran',
    GRADUATE_YEAR_UNDEFINED: 'Godina maturiranja nije definirana',
    GRADUATE_FIELD_UNDEFINED: 'Smjer nije definiran',
    SOMETHING_WENT_WRONG: 'Nešto nije u redu, pokušajte ponovno',
    DELETE_RECIPIENT: 'Obriši maturanta',
    EDIT_RECIPIENT: 'Uredi maturanta',
    DATE_FORMAT_IS_WRONG: 'Format datuma nije ispravan',
    MASS_ACTION: 'Radnja',
    MASS_ACTION_DROPDOWN_MESSAGE: 'Odaberi oznake...',
    SAVE: 'Spremi',
    ADD_TAGS_CONFIRMATION: 'Jeste li sigurni da želite dodati oznake maturantima?',
    REMOVE_TAGS_CONFIRMATION: 'Jeste li sigurni da želite ukloniti oznake maturantima?',
    MASS_ADD: 'Dodaj',
    MASS_REMOVE: 'Ukloni',
    RECIEVED_POLLS: 'Uspješno poslano upitnika',
    RECIEVED_POLLS_ERROR: 'Neuspješno poslano upitnika',
    WARNING_MESSAGE: 'Molimo odaberite barem jednu oznaku',
    QUIT_EDIT_MESSAGE: 'Jeste li sigurni da želite odustati od uređivanja maturanta?',
    GRADUATES: 'Maturanti'
  },
  USERS: {
    USERS_HEAD: 'Korisnici',
    ADD_NEW: 'Dodaj novog korisnika',
    SEARCH: 'Pretraži',
    SEARCH_USERS: 'Pretraži korisnike',
    DELETE_MESSAGE: 'Jeste li sigurni da želite obrisati korisnika?',
    QUIT_MESSAGE: 'Jeste li sigurni da želite odustati od kreiranja korisnika?',
    QUIT_EDIT_MESSAGE: 'Jeste li sigurni da želite odustati od uređivanja korisnika?',
    USER_NAME: 'Korisničko Ime',
    DELETE: 'Obriši',
    EMAIL: 'Email',
    PASSWORD: 'Lozinka',
    REPEATED_PASSWORD: 'Ponovite lozinku',
    EDIT: 'Uredi',
    CREATE_USER: 'Kreiraj novog korisnika',
    CREATE: 'Kreiraj',
    QUIT: 'Odustani',
    UPDATE: 'Ažuriraj',
    RESET: 'Resetiraj',
    ERROR: 'Greška',
    SUCCESSFULLY_DELETED_USER: 'Korisnik je uspješno izbrisan',
    SUCCESSFULLY_CREATED_USER: 'Korisnik je uspješno kreiran',
    SUCCESSFULLY_UPDATED_USER: 'Korisnik je uspješno ažuriran',
    USER_ALREADY_EXIST: 'Korisnik s ovom email adresom već postoji',
    EMAIL_FORMAT_IS_WRONG: 'Email adresa nije ispravna',
    FIRST_NAME_UNDEFINED: 'Ime nije definirano',
    LAST_NAME_UNDEFINED: 'Prezime nije definirano',
    EMAIL_UNDEFINED: 'Email nije definiran',
    SOMETHING_WENT_WRONG: 'Nešto nije u redu, pokušajte ponovno',
    DELETE_USER: 'Obriši korisnika',
    EDIT_USER: 'Uredi korisnika',
    USER_DELETE_YOURSELF: 'Ne možete izbrisati samoga sebe'
  },
  TAGS: {
    SUCCESSFULLY_CREATED_TAG: 'Oznaka je uspješno kreirana'
  },
  POLL_RESULTS: {
    EXPORT_TO_PDF: 'Izvezi u PDF',
    POLL_RESULTS: 'Rezultati istraživanja',
    DESCRIPTION: 'Opis:',
    INSTRUCTIONS: 'Upute:',
    TARGETED_AUDIENCE: 'Ciljane grupe ispitanika:',
    QUESTION: 'Pitanje',
    ONE_POSSIBLE_ANSWER: 'Jedan mogući odgovor',
    MORE_POSSIBLE_ANSWERS: 'Više mogućih odgovora',
    FREE_ENTRY: 'Slobodan unos',
    PARTICIPATED: 'Upitnik je ispunilo',
    PARTICIPATED_END: 'ispitanika.',
    NUMBER_OF_RECIPIENTS: 'Upitnik dostavljen do',
    NUMBER_OF_RECIPIENTS_END: 'ispitanika.',
    TOTAL_RECIPIENTS: 'Ciljane grupe sadržavaju',
    TOTAL_RECIPIENTS_END: 'ispitanika.',
    NAME: 'Istraživanje:'
  },
  POLL_SUBMISION: {
    DESCRIPTION: 'Opis',
    INSTRUCTIONS: 'Upute',
    FINISH: 'Pošalji',
    QUIT: 'Odustani',
    QUIT_MESSAGE: 'Jeste li sigurni da želite napustiti upitnik?',
    THIS_QUESTION_IS_REQUIRED: 'Odgovor na ovo pitanje je obavezan',
    ANSWER_ON_ALL_REQUIRED_QUESTIONS: 'Molimo vas da odgovorite na sva obavezna pitanja',
    ACTION_MESSAGES: {
      SUBMITTED: {
        HEAD: 'Hvala Vam na sudjelovanju u upitniku. Vaši odgovori su zabilježeni.'
      },
      QUIT: {
        HEAD: 'Odustali ste od ispunjavanja upitnika. Vaši odgovori neće biti spremljeni.'
      },
      COMPLETED: {
        HEAD: 'Već ste ispunili upitnik.'
      },
      FINISHED: {
        HEAD: 'Žao nam je, ovo istraživanje je završeno.'
      },
      IN_PROGRESS: {
        HEAD: 'Žao nam je, trenutno ne možete pristupiti ovom upitniku. Pokušajte ponovno kasnije.'
      }
    }
  },
  BREADCRUMBS: {
    Polls: 'Upitnici',
    Create_new: 'Kreiraj',
    Users: 'Korisnici',
    Results: 'Rezultati',
    Edit: 'Uredi',
    Recipients: 'Ispitanici',
    Upload_from_file: 'Učitaj iz datoteke',
    Graduates: 'Maturanti'
  },
  VALIDATION: {
    REQUIRED: 'Ovo polje je obavezno',
    EMAIL: 'Format email-a je pogrešan',
    VALID: 'Lozinka mora sadržavati sljedeće:',
    NO_QUESTIONS: 'Morate dodati barem jedno pitanje',
    REQUIREMENTS: 'Lozinka mora sadržavati sljedeće:',
    PASSWORD_LENGTH: 'Najmanje 8 znakova',
    PASSWORD_ONE_LOWER: 'Jedno malo slovo',
    PASSWORD_ONE_CAPITAL: 'Jedno veliko slovo',
    PASSWORD_ONE_NUMBER: 'Jedan broj',
    PASSWORD_ONE_SPECIAL: 'Jedan specijalni znak',
    REPEATED_PASSWORD: 'Lozinke se ne podudaraju'
  }
}
