import { Switch } from '@headlessui/react'
import { PlusIcon as PlusIconMini } from '@heroicons/react/20/solid'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import hr from 'date-fns/locale/hr'
import moment from 'moment'
import { useEffect, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Select, { createFilter } from 'react-select'
registerLocale('hr', hr)

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const Input = ({
  value,
  onChange,
  error,
  name,
  type,
  label,
  placeholder,
  style,
  disabled
}) => {
  const [passwordShow, setPasswordShow] = useState(false)
  return (
    <div className='mt-4'>
      <label className='block text-sm font-medium text-gray-700'>{label}</label>
      <div className='mt-1'>
        <div className='relative'>
          <input
            disabled={disabled}
            style={style}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value, name)}
            id={name}
            name={name}
            type={type !== 'password' ? type : passwordShow ? 'text' : 'password'}
            className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
          />
          {type === 'password' && passwordShow && (
            <button
              className='absolute right-2 top-[50%] translate-y-[-50%]'
              type='button'
              onClick={() => setPasswordShow(false)}
            >
              <EyeSlashIcon className='w-4' />
            </button>
          )}
          {type === 'password' && !passwordShow && (
            <button
              className='absolute right-2 top-[50%] translate-y-[-50%]'
              type='button'
              onClick={() => setPasswordShow(true)}
            >
              <EyeIcon className='w-4' />
            </button>
          )}
        </div>
        {error && <p className='text-red-700 text-[14px] mt-2'>{error}</p>}
      </div>
    </div>
  )
}

export const DatePickerInput = ({ value, onChange, error, name, type, label }) => {
  return (
    <div className='mt-4'>
      <label className='block text-sm font-medium text-gray-700'>{label}</label>
      <div className='mt-1'>
        <DatePicker
          selected={value}
          onChange={(date) => onChange(new Date(moment(date).format('YYYY-MM-DD')), name)}
          id={name}
          name={name}
          type={type}
          className='rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm w-full'
          dateFormat='dd.MM.yyyy.'
          locale='hr'
          maxDate={new Date()}
          shouldCloseOnSelect={true}
          onKeyDown={(e) => e.preventDefault()}
        />
        {error && <p className='text-red-700 text-[14px] mt-2'>{error}</p>}
      </div>
    </div>
  )
}

export const RoundBtn = ({ onClick, error, style }) => {
  return (
    <button
      type='button'
      style={style}
      onClick={onClick}
      error={error}
      className='inline-flex items-center rounded-full border border-transparent bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
    >
      <PlusIconMini className='h-5 w-5' aria-hidden='true' />
    </button>
  )
}

export const SquareBtnColored = ({ onClick, error, text, style, disabled, type = 'button' }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={style}
      error={error}
      type={type}
      className='inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
    >
      {text}
    </button>
  )
}
export const SquareBtnTransparent = ({
  onClick,
  error,
  text,
  style,
  disabled,
  type = 'button'
}) => {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      style={style}
      error={error}
      className='inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
    >
      {text}
    </button>
  )
}

export const TextArea = ({ value, onChange, error, name, type, label }) => {
  return (
    <div className='mt-5'>
      <label className='block text-sm font-medium text-gray-700'>{label}</label>
      <div className='mt-1'>
        <textarea
          value={value}
          onChange={(e) => onChange(e.target.value, name)}
          id={name}
          name={name}
          type={type}
          className='block w-full h-20 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
        />
      </div>
      {error && <p className='text-red-700 text-[14px] mt-2'>{error}</p>}
    </div>
  )
}

export const Toggle = ({ onClick, isChecked, error, style }) => {
  const [enabled, setEnabled] = useState(isChecked)
  return (
    <Switch
      onClick={onClick}
      checked={enabled}
      onChange={setEnabled}
      className='group relative mt-1 ml-auto flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
    >
      <span className='sr-only'>Use setting</span>
      <span
        aria-hidden='true'
        className='pointer-events-none absolute h-full w-full rounded-md bg-white'
      />
      <span
        aria-hidden='true'
        className={classNames(
          enabled ? 'bg-indigo-600' : 'bg-gray-200',
          'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
        )}
      />
      <span
        aria-hidden='true'
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
        )}
      />
    </Switch>
  )
}

export const BtnGroupDropdown = () => {
  return (
    <span className='inline-flex rounded-md shadow-sm'>
      <label htmlFor='message-type' className='sr-only'>
        Select message type
      </label>
      <select
        id='message-type'
        name='message-type'
        className='-ml-px block w-full rounded-l-none rounded-r-md border border-gray-300 bg-white py-2 pl-3 pr-9 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500'
      >
        <option>Unread messages</option>
        <option>Sent messages</option>
        <option>All messages</option>
      </select>
    </span>
  )
}

export const TagsSelectInput = ({
  value,
  onChange,
  tagNameValue,
  error,
  name,
  tagName,
  label,
  onSubmit,
  createNew = true
}) => {
  const { tagsGetAll } = useSelector((state) => state)
  const { tagsOptions } = tagsGetAll
  const { t } = useTranslation()

  return (
    <div className='custom-select-input mt-5'>
      <label htmlFor='email' className='block text-sm font-medium text-gray-700'>
        {label}
      </label>
      <div className='mt-1'>
        <Select
          className='sm:text-sm'
          onChange={(value) => onChange(value, name)}
          onInputChange={(value) => onChange(value, tagName)}
          inputValue={tagNameValue}
          closeMenuOnSelect={true}
          isMulti
          value={value}
          options={tagsOptions}
          filterOption={createFilter({ ignoreCase: true })}
          placeholder=''
          noOptionsMessage={() => {
            return (
              <div>
                <p className='text-center my-4 text-[18px]'>{t('GRADUATES.NO_RESULT')}</p>
                {createNew && (
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      onSubmit()
                    }}
                    className='rounded flex items-center gap-1 py-2 px-3 bg-indigo-500 text-white text-[14px] font-medium'
                  >
                    {t('GRADUATES.CREATE_NEW_TAG')}
                  </button>
                )}
              </div>
            )
          }}
        />
        <p className='text-red-700 text-[14px] mt-2'>{error && error}</p>
      </div>
    </div>
  )
}

export const TagsFilterInput = ({ filterByNewTag, displayTags, setDisplayTags }) => {
  const { t } = useTranslation()
  const { tagsGetAll } = useSelector((state) => state)
  const { tagsOptions } = tagsGetAll
  const [selectedValue, setSelectedValue] = useState([])

  useEffect(() => {
    if (displayTags) {
      setSelectedValue(displayTags.map((tag) => ({ label: tag, value: tag })))
    }
  }, [displayTags])

  return (
    <div className='custom-select-input'>
      <div className='mt-1'>
        <Select
          className='text-sm w-full'
          isMulti={true}
          isSearchable={true}
          isClearable={true}
          onChange={(value) => {
            setSelectedValue(value)
            setDisplayTags(value.map((tag) => tag.label))
            filterByNewTag(value.map((tag) => tag.label))
          }}
          value={selectedValue}
          options={
            tagsOptions &&
            tagsOptions.filter((tag) => !selectedValue.map((tag) => tag.label).includes(tag.label))
          }
          filterOption={createFilter({ ignoreCase: true })}
          placeholder={t('GRADUATES.SEARCH_TAGS')}
        />
      </div>
    </div>
  )
}

export const MassTagsInput = ({ setUpdateParams, updateParams, isDispatched }) => {
  const { tagsGetAll } = useSelector((state) => state)
  const { tagsOptions } = tagsGetAll
  const [selectedValue, setSelectedValue] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (isDispatched) {
      setSelectedValue([])
    } else {
      if (updateParams.tags.length > 0) {
        setSelectedValue(updateParams.tags.map((tag) => ({ label: tag.name, value: tag.id })))
      }
    }
  }, [updateParams.tags, isDispatched])

  return (
    <div className='custom-select-input'>
      <div className='mt-1'>
        <Select
          className='text-sm w-full md:w-[200px]'
          closeMenuOnSelect={true}
          isMulti
          value={selectedValue}
          options={tagsOptions}
          filterOption={createFilter({ ignoreCase: true })}
          placeholder={t('GRADUATES.MASS_ACTION_DROPDOWN_MESSAGE')}
          onChange={(value) => {
            setSelectedValue(value)
            setUpdateParams({
              ...updateParams,
              tags: value.map((tag) => ({ id: tag.value, name: tag.label }))
            })
          }}
        />
      </div>
    </div>
  )
}

export const YearsSelectInput = ({ value, onChange, error, name, type, label }) => {
  const years = Array.from(new Array(59), (val, index) => index + 1970).map((year) => ({
    value: year,
    label: year
  }))

  return (
    <div className='mt-5'>
      <label className='block text-sm font-medium text-gray-700'>{label}</label>
      <div className='mt-1'>
        <Select
          className='sm:text-sm'
          onChange={(value) => {
            onChange(value, name)
          }}
          name={name}
          options={years}
          value={typeof value === 'number' ? { value: value, label: value } : value}
          placeholder=''
        />
        {error && <p className='text-red-700 text-[14px] mt-2'>{error}</p>}
      </div>
    </div>
  )
}
