import { api } from '../../services/api.service'
import {
  RECIPIENT_CREATE_FAIL,
  RECIPIENT_CREATE_REQUEST,
  RECIPIENT_CREATE_SUCCESS,
  RECIPIENT_DELETE_FAIL,
  RECIPIENT_DELETE_REQUEST,
  RECIPIENT_DELETE_SUCCESS,
  RECIPIENT_GET_ALL_FAIL,
  RECIPIENT_GET_ALL_REQUEST,
  RECIPIENT_GET_ALL_SUCCESS,
  RECIPIENT_GET_ONE_FAIL,
  RECIPIENT_GET_ONE_REQUEST,
  RECIPIENT_GET_ONE_SUCCESS,
  RECIPIENT_UPDATE_FAIL,
  RECIPIENT_UPDATE_REQUEST,
  RECIPIENT_UPDATE_SUCCESS,
  RECIPIENT_UPLOAD_REQUEST,
  RECIPIENT_UPLOAD_SUCCESS
} from '../constants/recipientConstants'

export const getAll =
  (offset = 0, queryString = '', tagsString = '', positionsString = '', graduated = false) =>
  async (dispatch) => {
    dispatch({ type: RECIPIENT_GET_ALL_REQUEST, payload: {} })
    try {
      const response = await api.get(`/recipient`, {
        params: {
          offset: offset,
          query: queryString,
          tags: String(tagsString),
          positions: positionsString,
          graduated: graduated
        }
      })
      dispatch({ type: RECIPIENT_GET_ALL_SUCCESS, payload: response.data })
    } catch (error) {
      console.log(error)
      dispatch({
        type: RECIPIENT_GET_ALL_FAIL,
        payload: error.response.data.message
      })
    }
  }

export const deleteRecipient = (id) => async (dispatch) => {
  dispatch({ type: RECIPIENT_DELETE_REQUEST, payload: {} })
  try {
    await api.delete(`/recipient/${id}`)
    dispatch({ type: RECIPIENT_DELETE_SUCCESS, payload: true })
  } catch (error) {
    console.log(error)
    dispatch({
      type: RECIPIENT_DELETE_FAIL,
      payload: error.response.data.message
    })
  }
}

export const createRecipient = (inputs) => async (dispatch) => {
  dispatch({ type: RECIPIENT_CREATE_REQUEST, payload: {} })
  const tagsIds = inputs.tags.map((tag) => tag.value)
  const { firstName, lastName, email, dateOfBirth, graduated, graduateYear, graduateField } = inputs
  try {
    const response = await api.post(`/recipient`, {
      firstName,
      lastName,
      email,
      tagsIds,
      dateOfBirth,
      graduateYear,
      graduateField,
      graduated
    })
    dispatch({ type: RECIPIENT_CREATE_SUCCESS, payload: response.data.tag })
  } catch (error) {
    console.log(error)
    dispatch({
      type: RECIPIENT_CREATE_FAIL,
      payload: error.response.data.message
    })
  }
}

export const updateRecipient = (inputs, id) => async (dispatch) => {
  dispatch({ type: RECIPIENT_UPDATE_REQUEST, payload: {} })
  const tagsIds = inputs.tags.map((tag) => tag.value)
  const { firstName, lastName, email, dateOfBirth, graduateYear, graduateField } = inputs
  try {
    const response = await api.patch(`/recipient/${id}`, {
      firstName,
      lastName,
      email,
      tagsIds,
      dateOfBirth,
      graduateYear,
      graduateField
    })
    dispatch({
      type: RECIPIENT_UPDATE_SUCCESS,
      payload: response.data.recipient
    })
  } catch (error) {
    console.log(error)
    dispatch({
      type: RECIPIENT_UPDATE_FAIL,
      payload: error.response.data.message
    })
  }
}

export const getOneRecipient = (id) => async (dispatch) => {
  dispatch({ type: RECIPIENT_GET_ONE_REQUEST, payload: {} })
  try {
    const response = await api.get(`/recipient/${id}`)
    dispatch({
      type: RECIPIENT_GET_ONE_SUCCESS,
      payload: response.data.recipient
    })
  } catch (error) {
    console.log(error)
    dispatch({
      type: RECIPIENT_GET_ONE_FAIL,
      payload: error.response.data.message
    })
  }
}

export const uploadRecipients = (file, graduated) => async (dispatch) => {
  dispatch({ type: RECIPIENT_UPLOAD_REQUEST, payload: {} })
  try {
    const formData = new FormData()
    formData.append('file', file)

    const response = await api.post(`/recipient/upload`, formData, {
      params: { graduated },
      headers: { 'Content-Type': 'multipart/form-data' }
    })

    dispatch({ type: RECIPIENT_UPLOAD_SUCCESS, payload: response.data })
  } catch (error) {
    console.log(error)
  }
}

export const massUpdateRecipients = (params) => async (dispatch) => {
  dispatch({ type: RECIPIENT_UPDATE_REQUEST, payload: {} })
  try {
    const response = await api.patch(`/recipient/mass-update/byFilters`, {
      params
    })
    dispatch({ type: RECIPIENT_GET_ALL_SUCCESS, payload: response.data })
  } catch (error) {
    console.log(error)
    dispatch({
      type: RECIPIENT_UPDATE_FAIL,
      payload: error.response.data.message
    })
  }
}
