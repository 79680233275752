import {
  EllipsisHorizontalCircleIcon,
  PencilSquareIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ErrorAlert, SuccessAlert } from '../../../../components/Alerts'
import LoadingOverlay from '../../../../components/LoadingOverlay'
import Pagination from '../../../../components/Pagination'
import PositionsPopupModel from './PositionsPopupModal'

export const RecipientsTable = ({ onOpenModal, setOffset, offset }) => {
  const [initialLoad, setInitialLoad] = useState(false)
  const { recipientGetAll, recipientDelete } = useSelector((state) => state)
  const { loading: loadingGetAll, recipients, count } = recipientGetAll
  const { loading: loadingDelete, success: successDelete, error: errorDelete } = recipientDelete
  const [dataToModal, setDataToModal] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [type, setType] = useState('')
  const { t } = useTranslation()

  const formatDate = (d) => {
    return moment(d).format('DD.MM.YYYY.')
  }
  const openWorkAndEducationModal = (recipient, index, type) => {
    setDataToModal(recipient, index)
    setType(type)
    setOpenModal(true)
  }

  useEffect(() => {
    setInitialLoad(true)
  }, [])

  useEffect(() => {
    if (initialLoad) {
      setOffset(0)
    }
  }, [initialLoad, setOffset])

  return (
    <div className='mt-8 flex flex-col'>
      <div className='overflow-x-auto rounded-lg'>
        <div className='inline-block min-w-full align-middle'>
          {successDelete && (
            <SuccessAlert message={t('GRADUATES.SUCCESSFULLY_DELETED_RECIPIENT')} />
          )}
          {errorDelete && <ErrorAlert message={t(errorDelete)} />}
          {openModal && (
            <PositionsPopupModel
              positions={dataToModal}
              onConfirm={() => setOpenModal(false)}
              type={type}
            />
          )}
          <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg relative'>
            {loadingGetAll || (loadingDelete && <LoadingOverlay />)}
            <table className='min-w-full divide-y divide-gray-300 overflow-hidden'>
              <thead className='bg-gray-50'>
                <tr>
                  {[
                    `${t('GRADUATES.FIRST_LAST_NAME')}`,
                    `${t('GRADUATES.EMAIL')}`,
                    `${t('GRADUATES.DATE_OF_GRADUATION')}`,
                    `${t('GRADUATES.GRADUATE_MAJOR')}`,
                    `${t('GRADUATES.EDUCATION')}`,
                    `${t('GRADUATES.WORK')}`,
                    ``
                  ].map((head, index) => {
                    return (
                      <th
                        key={index}
                        scope='col'
                        className='p-3 text-left text-sm font-semibold text-gray-900'
                      >
                        {head}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {recipients &&
                  recipients.map((recipient, index) => (
                    <tr key={index}>
                      <td className='whitespace-nowrap text-sm text-gray-500 pl-3'>
                        {recipient.firstName} {recipient.lastName}
                      </td>
                      <td className='whitespace-nowrap text-sm text-gray-500 pl-3'>
                        {recipient.email}
                      </td>
                      <td className='whitespace-nowrap text-sm text-gray-500 pl-3'>
                        {recipient.graduateYear && recipient.graduateYear + '.'}
                      </td>
                      <td className='whitespace-nowrap text-sm text-gray-500 pl-3'>
                        {recipient.graduateField}
                      </td>
                      <td className='whitespace-nowrap text-sm text-gray-500 pl-3'>
                        {recipient.positions?.education?.map((position, index) => {
                          if (index === recipient.positions.education.length - 1) {
                            return (
                              <div className='flex items-center gap-2' key={index}>
                                <p className='text-gray-500'>
                                  {' '}
                                  {position.institution}
                                  {position.major && `, ${position.major}`}
                                </p>
                                {recipient.positions?.education?.length > 1 && (
                                  <EllipsisHorizontalCircleIcon
                                    onClick={() =>
                                      openWorkAndEducationModal(
                                        recipient.positions,
                                        index,
                                        'education'
                                      )
                                    }
                                    className='w-[18px] text-gray-900 cursor-pointer'
                                  />
                                )}
                              </div>
                            )
                          } else {
                            return null
                          }
                        })}
                      </td>
                      <td className='whitespace-nowrap text-sm text-gray-500 pl-3'>
                        {recipient.positions?.work?.map((position, index) => {
                          if (index === recipient.positions.work.length - 1) {
                            return (
                              <div className='flex items-center gap-2' key={index}>
                                <p className='text-gray-500'>
                                  {' '}
                                  {position.company}
                                  {position.position && `, ${position.position}`}
                                </p>
                                {recipient.positions?.work?.length > 1 && (
                                  <EllipsisHorizontalCircleIcon
                                    onClick={() =>
                                      openWorkAndEducationModal(recipient.positions, index, 'work')
                                    }
                                    className='w-[18px] text-gray-900 cursor-pointer'
                                  />
                                )}
                              </div>
                            )
                          } else {
                            return null
                          }
                        })}
                      </td>
                      <td className='whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium flex items-center justify-end'>
                        <Link
                          to={`/graduates/edit/${recipient.id}`}
                          className='text-indigo-600 hover:text-indigo-900'
                          data-tooltip={t('GRADUATES.EDIT_RECIPIENT')}
                        >
                          <PencilSquareIcon className='w-[18px] text-gray-900' />
                        </Link>
                        <button
                          type='button'
                          onClick={() => onOpenModal(recipient.id)}
                          className='ml-2 text-indigo-600 hover:text-indigo-900'
                          data-tooltip={t('GRADUATES.DELETE_RECIPIENT')}
                        >
                          <TrashIcon className='w-[18px] text-gray-900' />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination count={count} onChange={setOffset} offset={offset} />
    </div>
  )
}

export default RecipientsTable
