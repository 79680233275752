import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ErrorAlert, SuccessAlert } from '../../../components/Alerts'
import {
  DatePickerInput,
  Input,
  SquareBtnColored,
  SquareBtnTransparent,
  YearsSelectInput
} from '../../../components/FormComponents'
import LoadingOverlay from '../../../components/LoadingOverlay'
import Modal from '../../../components/Modal'
import {
  createRecipient,
  getOneRecipient,
  updateRecipient
} from '../../../redux/actions/recipentsActions'
import { getAll } from '../../../redux/actions/tagsActions'
import {
  RECIPIENT_CREATE_RESET,
  RECIPIENT_GET_ONE_RESET,
  RECIPIENT_UPDATE_RESET
} from '../../../redux/constants/recipientConstants'
import { TAGS_CREATE_RESET } from '../../../redux/constants/tagsConstants'
import { validation } from '../../../services/validation/recipientForm'

export const RecipientsForm = () => {
  let errors = {}
  const [initialLoad, setInitialLoad] = useState(false)
  const [createCancelModal, setCreateCancelModal] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [inputs, setInputs] = useState({
    email: '',
    firstName: '',
    lastName: '',
    tags: [],
    tagName: '',
    dateOfBirth: '',
    graduated: true,
    graduateYear: { value: '', label: '' },
    graduateField: ''
  })

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { recipientId } = useParams()
  const location = useLocation()

  const { tagsCreate, recipientCreate, recipientUpdate, recipientGetOne } = useSelector(
    (state) => state
  )
  const {
    success: successTagCreate,
    tag,
    loading: loadingTagCreate,
    error: errorTagCreate
  } = tagsCreate
  const {
    loading: loadingRecipientCreate,
    success: successRecipientCreate,
    error: errorRecipientCreate
  } = recipientCreate
  const {
    loading: loadingRecipientUpdate,
    success: successRecipientUpdate,
    error: errorRecipientUpdate
  } = recipientUpdate
  const {
    loading: loadingRecipientGetOne,
    error: errorRecipientGetOne,
    recipient
  } = recipientGetOne

  useEffect(() => {
    if (location.pathname.includes('edit')) {
      dispatch(getOneRecipient(recipientId))
    }
    setInitialLoad(true)
    dispatch({ type: RECIPIENT_CREATE_RESET })
    dispatch({ type: RECIPIENT_UPDATE_RESET })
    dispatch({ type: TAGS_CREATE_RESET })
  }, [dispatch, recipientId, location.pathname])

  useEffect(() => {
    if (recipient) {
      setInputs({
        firstName: recipient.firstName,
        lastName: recipient.lastName,
        email: recipient.email,
        tags: recipient.tags.map((tag) => ({ value: tag.id, label: tag.name })),
        dateOfBirth: recipient.dateOfBirth && new Date(recipient.dateOfBirth),
        graduated: recipient.graduated,
        graduateYear: recipient.graduateYear
          ? {
              value: recipient.graduateYear,
              label: recipient.graduateYear
            }
          : { value: '', label: '' },
        graduateField: recipient.graduateField
      })
    }
    if (errorRecipientGetOne && initialLoad) {
      dispatch({ type: RECIPIENT_GET_ONE_RESET })
      setTimeout(() => {
        navigate('/recipients', { replace: true })
      }, 1000)
    }
  }, [recipient, errorRecipientGetOne])

  useEffect(() => {
    dispatch(getAll(''))
    if (tag && successTagCreate) {
      setInputs({
        ...inputs,
        tagName: '',
        ['tags']: [...inputs.tags, { value: tag.id, label: tag.name }]
      })
    }
  }, [successTagCreate])

  useEffect(() => {
    setInputs({
      firstName: '',
      lastName: '',
      email: '',
      tags: [],
      dateOfBirth: '',
      graduated: true,
      graduateYear: { value: '', label: '' },
      graduateField: ''
    })
    setSubmitted(false)
  }, [successRecipientCreate])

  const setVal = (value, name) => {
    switch (name) {
      case 'tags':
        setInputs({ ...inputs, tagName: '', [name]: value })
        break
      case 'tagName':
        dispatch(getAll(value))
        setInputs({ ...inputs, [name]: value })
        break
      case 'dateOfBirth':
        setInputs({ ...inputs, [name]: value })
        break
      case 'graduateYear':
        setInputs({ ...inputs, [name]: value })
        break
      default:
        setInputs({ ...inputs, [name]: value })
        break
    }
  }

  const onCreate = (e) => {
    e.preventDefault()
    setSubmitted(true)
    errors = validation(inputs)
    if (!isEmpty(errors)) {
      return
    } else {
      inputs.graduateYear = inputs.graduateYear.value
      dispatch(createRecipient(inputs))
      dispatch({ type: TAGS_CREATE_RESET })
    }
  }

  const onUpdate = (e) => {
    e.preventDefault()
    setSubmitted(true)
    errors = validation(inputs)
    if (!isEmpty(errors)) {
      return
    } else {
      inputs.graduateYear = inputs.graduateYear.value
      dispatch(updateRecipient(inputs, recipientId))
      dispatch({ type: TAGS_CREATE_RESET })
    }
  }

  useEffect(() => {
    if (successRecipientUpdate || successRecipientCreate) {
      setTimeout(() => {
        navigate('/recipients', { replace: true })
        dispatch({ type: RECIPIENT_UPDATE_RESET })
        dispatch({ type: RECIPIENT_CREATE_RESET })
      }, 1000)
    }
  }, [successRecipientUpdate, successRecipientCreate])

  if (submitted) {
    errors = validation(inputs)
  }

  return (
    <div className='w-full'>
      {createCancelModal && (
        <Modal
          onConfirm={() => navigate('/recipients')}
          onCancel={() => setCreateCancelModal(false)}
          message={recipientId ? t('GRADUATES.QUIT_EDIT_MESSAGE') : t('GRADUATES.QUIT_MESSAGE')}
        />
      )}
      <form
        onSubmit={recipientId ? onUpdate : onCreate}
        className='max-w-[600px] mx-auto bg-white px-[40px] py-12 shadow relative'
      >
        {loadingRecipientCreate ||
          loadingRecipientGetOne ||
          loadingRecipientUpdate ||
          (loadingTagCreate && <LoadingOverlay />)}
        {successTagCreate && <SuccessAlert message={t('TAGS.SUCCESSFULLY_CREATED_TAG')} />}
        {successRecipientCreate && (
          <SuccessAlert message={t('GRADUATES.SUCCESSFULLY_CREATED_RECIPIENT')} />
        )}
        {successRecipientUpdate && (
          <SuccessAlert message={t('GRADUATES.SUCCESSFULLY_UPDATED_RECIPIENT')} />
        )}
        {errorRecipientCreate && <ErrorAlert message={t(errorRecipientCreate)} />}
        {errorRecipientUpdate && <ErrorAlert message={t(errorRecipientUpdate)} />}
        {errorTagCreate && <ErrorAlert message={errorTagCreate} />}
        <div className='space-y-8 divide-y divide-gray-200'>
          <div className=''>
            <h1 className='text-[28px] font-semibold text-gray-900'>
              {recipientId ? t('GRADUATES.EDIT_RECIPIENT') : t('GRADUATES.CREATE_RECIPIENT')}
            </h1>
            <div className='mt-6'>
              <Input
                value={inputs['firstName']}
                type='text'
                onChange={setVal}
                error={errors['firstName']}
                name='firstName'
                label={t('GRADUATES.FIRST_NAME')}
                placeholder={t('GRADUATES.FIRST_NAME')}
              />
              <Input
                value={inputs['lastName']}
                type='text'
                onChange={setVal}
                error={errors['lastName']}
                name='lastName'
                label={t('GRADUATES.LAST_NAME')}
                placeholder={t('GRADUATES.LAST_NAME')}
              />
              <Input
                value={inputs['email']}
                type='text'
                onChange={setVal}
                error={errors['email']}
                name='email'
                label={t('GRADUATES.EMAIL')}
                placeholder={t('GRADUATES.EMAIL')}
              />
              <Input
                value={inputs['graduateField']}
                type='text'
                onChange={setVal}
                error={errors['graduateField']}
                name='graduateField'
                label={t('GRADUATES.GRADUATE_MAJOR')}
                placeholder={t('GRADUATES.GRADUATE_MAJOR')}
              />
              <YearsSelectInput
                value={inputs['graduateYear']}
                onChange={setVal}
                error={errors['graduateYear']}
                name='graduateYear'
                label={t('GRADUATES.DATE_OF_GRADUATION')}
              />
              <DatePickerInput
                value={inputs['dateOfBirth']}
                onChange={setVal}
                error={errors['dateOfBirth']}
                name='dateOfBirth'
                label={t('GRADUATES.DATE_OF_BIRTH')}
              />
            </div>
          </div>
        </div>
        <div className='mt-5'>
          <div className='flex justify-start'>
            {recipientId ? (
              <>
                <SquareBtnColored
                  onClick={onUpdate}
                  type='submit'
                  style={{ marginRight: 5 }}
                  text={t('GRADUATES.UPDATE')}
                />
                <SquareBtnTransparent
                  text={t('GRADUATES.QUIT')}
                  onClick={() => setCreateCancelModal(true)}
                />
              </>
            ) : (
              <>
                <SquareBtnColored
                  type='submit'
                  style={{ marginRight: 5 }}
                  text={t('GRADUATES.CREATE')}
                  onClick={onCreate}
                />
                <SquareBtnTransparent
                  onClick={() => setCreateCancelModal(true)}
                  text={t('GRADUATES.QUIT')}
                />
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}

export default RecipientsForm
