import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { SuccessAlert } from '../../../components/Alerts'
import LoadingOverlay from '../../../components/LoadingOverlay'
import Pagination from '../../../components/Pagination'
import { uploadRecipients } from '../../../redux/actions/recipentsActions'
import { RECIPIENT_UPLOAD_RESET } from '../../../redux/constants/recipientConstants'

export const RecipientsUploadFromFile = () => {
  const inputFileRef = useRef(null)
  const [offset, setOffset] = useState(0)
  const [errors, setErrors] = useState([])

  const { recipientUpload } = useSelector((state) => state)
  const { loading, response } = recipientUpload

  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch({ type: RECIPIENT_UPLOAD_RESET })
  }, [dispatch])

  useEffect(() => {
    if (response && response.errors) {
      let errorsArr = []
      for (let i = offset * 10; i < offset * 10 + 10; i++) {
        if (response.errors[i]) {
          errorsArr.push(response.errors[i])
        }
      }
      setErrors(errorsArr)
    }
  }, [response, offset])

  const triggerInput = () => {
    inputFileRef.current.click()
  }

  const handleFileChange = (e) => {
    dispatch(uploadRecipients(e.target.files[0], 'true'))
    e.target.value = null
  }

  return (
    <div className='w-full'>
      <div className='mb-5'>
        <h1 className='text-[28px] text-center font-semibold text-gray-900 mb-12'>
          {t('GRADUATES.UPLOAD_FROM_CSV_FILE')}
        </h1>
        <div>
          <p>{t('GRADUATES.UPLOAD_INSTRUCTIONS')}</p>
          <div className='flex flex-col w-full'>
            <div className='overflow-x-auto'>
              <div className='inline-block min-w-full py-2 align-middle'>
                <div className='overflow-hidden relative'>
                  <table className='w-full divide-y divide-gray-300'>
                    <thead className='bg-white'>
                      <tr className='divide-x divide-gray-300'>
                        {[
                          `ime`,
                          `prezime`,
                          `email`,
                          `oznake`,
                          `datumRodenja`,
                          `godinaMaturiranja`,
                          `smjer`
                        ].map((head, index) => {
                          return (
                            <th
                              key={index}
                              scope='col'
                              className='py-1 pl-1 pr-2 text-left text-sm font-medium text-gray-900'
                            >
                              {head}
                            </th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      <tr className='divide-x divide-gray-300'>
                        <td className='whitespace-nowrap py-1 pl-1 pr-2 text-sm text-gray-900'>
                          Deanna
                        </td>
                        <td className='whitespace-nowrap px-1 py-1 text-sm text-gray-900'>Dunn</td>
                        <td className='whitespace-nowrap px-1 py-1 text-sm text-gray-900'>
                          deanna.dunn@example.com
                        </td>
                        <td className='whitespace-nowrap px-1 py-1 text-sm text-gray-900'>
                          Učenik, Roditelj, Učitelj
                        </td>
                        <td className='whitespace-nowrap px-1 py-1 text-sm text-gray-900'>
                          12.02.2001.
                        </td>
                        <td className='whitespace-nowrap px-1 py-1 text-sm text-gray-900'>2019</td>
                        <td className='whitespace-nowrap px-1 py-1 text-sm text-gray-900'>
                          Elektrotehničar računalstva
                        </td>
                      </tr>
                      <tr className='divide-x divide-gray-300'>
                        <td className='whitespace-nowrap py-1 pl-1 pr-2 text-sm text-gray-900'>
                          Nevaeh
                        </td>
                        <td className='whitespace-nowrap px-1 py-1 text-sm text-gray-900'>
                          Steward
                        </td>
                        <td className='whitespace-nowrap px-1 py-1 text-sm text-gray-900'>
                          nevaeh.steward@example.com
                        </td>
                        <td className='whitespace-nowrap px-1 py-1 text-sm text-gray-900'>
                          Profesor, Roditelj
                        </td>
                        <td className='whitespace-nowrap px-1 py-1 text-sm text-gray-900'>
                          25.05.1985.
                        </td>
                        <td className='whitespace-nowrap px-1 py-1 text-sm text-gray-900'>2019</td>
                        <td className='whitespace-nowrap px-1 py-1 text-sm text-gray-900'>
                          Autoelektričar
                        </td>
                      </tr>
                      <tr className='divide-x divide-gray-300'>
                        <td className='whitespace-nowrap py-1 pl-1 pr-2 text-sm text-gray-900'>
                          Rosemary
                        </td>
                        <td className='whitespace-nowrap px-1 py-1 text-sm text-gray-900'>
                          Harris
                        </td>
                        <td className='whitespace-nowrap px-1 py-1 text-sm text-gray-900'>
                          rosemary.harris@example.com
                        </td>
                        <td className='whitespace-nowrap px-1 py-1 text-sm text-gray-900'>
                          Profesor
                        </td>
                        <td className='whitespace-nowrap px-1 py-1 text-sm text-gray-900'>
                          01.01.1980.
                        </td>
                        <td className='whitespace-nowrap px-1 py-1 text-sm text-gray-900'>2019</td>
                        <td className='whitespace-nowrap px-1 py-1 text-sm text-gray-900'>
                          Mehatroničar
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <p>{t('GRADUATES.UPLOAD_INSTRUCTIONS_SECOND')}</p>
        </div>
        {loading && <LoadingOverlay />}
        {response && response.success && (
          <SuccessAlert message={t('GRADUATES.SUCCESSFULLY_UPLOADED_RECIPIENTS')} />
        )}
        {response && response.errors && (
          <div className='mt-12'>
            <p>{t('GRADUATES.UPLOAD_FROM_FILE_ERROR')}</p>
            <div className='flex flex-col w-full'>
              <div className='overflow-x-auto rounded-lg'>
                <div className='inline-block min-w-full py-2 align-middle'>
                  <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg relative'>
                    <table className='min-w-full divide-y divide-gray-300'>
                      <thead className='bg-gray-50'>
                        <tr>
                          {[
                            `${t('GRADUATES.FIRST_NAME')}`,
                            `${t('GRADUATES.LAST_NAME')}`,
                            `${t('GRADUATES.EMAIL')}`,
                            `${t('GRADUATES.ERROR')}`
                          ].map((head, index) => {
                            return (
                              <th
                                key={index}
                                scope='col'
                                className='px-3 py-3 text-left text-sm font-semibold text-gray-900'
                              >
                                {head}
                              </th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody className='divide-y divide-gray-200 bg-white'>
                        {errors.map((error, index) => (
                          <tr key={index}>
                            <td className='whitespace-nowrap px-3 py-3 text-sm text-gray-500'>
                              {error.dto.firstName}
                            </td>
                            <td className='whitespace-nowrap px-3 py-3 text-sm text-gray-500'>
                              {error.dto.lastName}
                            </td>
                            <td className='whitespace-nowrap px-3 py-3 text-sm text-gray-500'>
                              {error.dto.email}
                            </td>
                            <td className='whitespace-nowrap px-3 py-3 text-sm text-gray-500'>
                              {t(`GRADUATES.${error.message}`)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <Pagination count={response.errors.length} onChange={setOffset} />
            </div>
          </div>
        )}
      </div>
      <button
        onClick={triggerInput}
        type='button'
        className='block mt-20 mx-auto justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
      >
        {t('GRADUATES.UPLOAD_FROM_CSV_FILE')}
      </button>
      <input
        onChange={handleFileChange}
        ref={inputFileRef}
        type='file'
        accept='.csv'
        className='hidden'
      />
    </div>
  )
}

export default RecipientsUploadFromFile
