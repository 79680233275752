export const en = {
  MODAL: {
    YES: "Yes, I'm sure",
    NO: 'No, cancel'
  },
  SIGN_IN: {
    SIGN_IN_HEAD: 'Sign in to your account',
    EMAIL: 'Email',
    PASSWORD: 'Password',
    SIGN_IN: 'Sign in',
    USER_NOT_FOUND: 'User not found',
    WRONG_PASSWORD: 'Password incorrect',
    SOMETHING_WENT_WRONG: 'Something went wrong, please try again'
  },
  SIDE_NAV: {
    RECIPIENTS: 'Recipients',
    POLLS: 'Polls',
    USERS: 'Users',
    SIGN_OUT: 'Sign out',
    LOGOUT_MESSAGE: 'Are you sure to want to sign out?'
  },
  TOP_NAV: {
    ENGLISH: 'English',
    CROATIAN: 'Croatian'
  },
  POLLS: {
    POLLS_CANCELLATION: 'Are you sure you want to quit creating new poll?',
    POLLS_EDIT_CANCELLATION: 'Are you sure you want to quit editing poll?',
    POLLS_HEAD: 'Polls',
    POLLS_CREATE_NEW: 'Create new poll',
    POLLS_EDIT: 'Edit poll',
    POLL_NAME: 'Poll name',
    PUBLICATION_DATE: 'Publication date',
    END_DATE: 'End date',
    STATUS: 'Status',
    RECIPIENTS: 'Recipients',
    NUMBER_OF_RECIPIENTS: 'Number of recipients',
    DESCRIPTION: 'Description',
    INSTRUCTIONS: 'Instructions',
    TARGETED_AUDIENCE: 'Targeted audience',
    ADD_QUESTION: 'Add question',
    SAVE: 'Save',
    QUIT: 'Quit',
    QUESTION: 'Question',
    ENTER_QUESTION: 'Enter question',
    ADD_ANSWER: 'Add answer',
    ANSWER_TYPE: 'Answer type',
    REQUIRED: 'Required',
    ONE_OPTION_CHOICE: 'One option choice',
    MULTIPLE_OPTION_CHOICE: 'Multiple options choice',
    TEXT_INPUT: 'Text input',
    OFFERED_ANSWERS: 'Offered answers:',
    ENTER_ANSWER: 'Enter answer',
    SUCCESSFULLY_CREATED_POLL: 'Successfully created new poll',
    CREATED_POLL_FAIL: 'Something went wrong, please try again',
    TYPE_IN_ANSWER: 'Type in answer'
  },

  POLL_LIST: {
    FINISH_POLL_REQUEST: 'Are you sure to want to finish poll?',
    PUBLISH_POLL_REQUEST: 'Are you sure to want to publish poll?',
    DELETE_POLL_REQUEST: 'Are you sure to want to delete poll?',
    FINISH_POLL_SUCCESS: 'Successfully finished poll',
    PUBLISH_POLL_SUCCESS: 'Successfully published poll',
    DELETE_POLL_SUCCESS: 'Successfully deleted poll',
    FINISH_POLL: 'Finish poll',
    DELETE_POLL: 'Delete poll',
    EDIT_POLL: 'Edit poll',
    PUBLISH_POLL: 'Publish poll',
    CLONE_POLL: 'Clone poll',
    POLL_PREVIEW: 'Poll preview'
  },

  GRADUATES: {
    RECIPIENTS_HEAD: 'Graduates',
    ADD_NEW: 'Add New Graduate',
    ADD_NEW_FROM_FILE: 'Add Graduates from file',
    SEARCH: 'Search',
    SEARCH_RECIPIENTS: 'Search graduates',
    DELETE_MESSAGE: 'Are you sure you want to delete this graduate?',
    QUIT_MESSAGE: 'Are you sure you want to quit creating new graduate?',
    QUIT_EDIT_MESSAGE: 'Are you sure you want to quit editing this graduate?',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    DELETE: 'Delete',
    EMAIL: 'Email',
    TAGS: 'Tags',
    EDIT: 'Edit',
    CREATE_RECIPIENT: 'Create new graduate',
    SELECT_TAGS: 'Select tags',
    CREATE: 'Create',
    QUIT: 'Quit',
    UPDATE: 'Update',
    RESET: 'Reset',
    UPLOAD_FROM_CSV_FILE: 'Upload graduates from CSV file',
    UPLOAD: 'Upload',
    UPLOAD_INSTRUCTIONS: 'CSV file must be in next format',
    UPLOAD_INSTRUCTIONS_SECOND:
      'It is recommended that the file does not contain more than 1000 graduates',
    ERROR: 'Error',
    UPLOAD_FROM_FILE_ERROR: 'The following graduates were not successfully created',
    SUCCESSFULLY_DELETED_RECIPIENT: 'Successfully deleted graduate',
    SUCCESSFULLY_CREATED_RECIPIENT: 'Successfully created new graduate',
    SUCCESSFULLY_UPDATED_RECIPIENT: 'Successfully updated graduate',
    SUCCESSFULLY_UPLOADED_RECIPIENTS: 'Successfully uploaded graduates',
    RECIPIENT_ALREADY_EXIST: 'Graduate with this email already exist',
    RECIPIENT_HAS_SUBMITTED_POLL: 'Graduate has submitted poll',
    EMAIL_FORMAT_IS_WRONG: 'Email format is wrong',
    FIRST_NAME_UNDEFINED: 'First name is not defined',
    LAST_NAME_UNDEFINED: 'Last name is not defined',
    EMAIL_UNDEFINED: 'Email is not defined',
    SOMETHING_WENT_WRONG: 'Something went wrong, please try again',
    DELETE_RECIPIENT: 'Delete graduate',
    EDIT_RECIPIENT: 'Edit graduate'
  },
  USERS: {
    USERS_HEAD: 'Users',
    ADD_NEW: 'Add New User',
    SEARCH: 'Search',
    SEARCH_USERS: 'Search users',
    DELETE_MESSAGE: 'Are you sure you want to delete this user?',
    QUIT_MESSAGE: 'Are you sure you want to quit creating new user?',
    QUIT_EDIT_MESSAGE: 'Are you sure you want to quit editing this user?',
    USER_NAME: 'User Name',
    DELETE: 'Delete',
    EMAIL: 'Email',
    PASSWORD: 'Password',
    REPEATED_PASSWORD: 'Repeat password',
    EDIT: 'Edit',
    CREATE_USER: 'Create new user',
    CREATE: 'Create',
    QUIT: 'Quit',
    UPDATE: 'Update',
    RESET: 'Reset',
    ERROR: 'Error',
    SUCCESSFULLY_DELETED_USER: 'Successfully deleted user',
    SUCCESSFULLY_CREATED_USER: 'Successfully created new user',
    SUCCESSFULLY_UPDATED_USER: 'Successfully updated user',
    USER_ALREADY_EXIST: 'User with this email already exist',
    EMAIL_FORMAT_IS_WRONG: 'Email format is wrong',
    FIRST_NAME_UNDEFINED: 'First name is not defined',
    LAST_NAME_UNDEFINED: 'Last name is not defined',
    EMAIL_UNDEFINED: 'Email is not defined',
    SOMETHING_WENT_WRONG: 'Something went wrong, please try again',
    DELETE_USER: 'Delete user',
    EDIT_USER: 'Edit user',
    USER_DELETE_YOURSELF: 'You cannot delete yourself'
  },
  TAGS: {
    SUCCESSFULLY_CREATED_TAG: 'Successfully created new tag'
  },
  POLL_RESULTS: {
    EXPORT_TO_PDF: 'Export to PDF',
    POLL_RESULTS: 'Poll results',
    DESCRIPTION: 'Description',
    INSTRUCTIONS: 'Instructions',
    TARGETED_AUDIENCE: 'Targeted audience:',
    NUMBER_OF_RECIPIENTS: 'Number of recipients:',
    QUESTION: 'Question',
    ONE_POSSIBLE_ANSWER: 'One possible answer',
    MORE_POSSIBLE_ANSWERS: 'More possible answers',
    FREE_ENTRY: 'Free entry'
  },
  POLL_SUBMISION: {
    DESCRIPTION: 'Description',
    INSTRUCTIONS: 'Instructions',
    FINISH: 'Finish',
    QUIT: 'Quit',
    QUIT_MESSAGE: 'Are you sure to want to quit?',
    THIS_QUESTION_IS_REQUIRED: 'Answer for this question is required',
    ANSWER_ON_ALL_REQUIRED_QUESTIONS: 'Please answer on all required questions',
    ACTION_MESSAGES: {
      SUBMITTED: {
        HEAD: 'Thank you for submitting poll',
        MESSAGE:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
      },
      QUIT: {
        HEAD: 'Cancellation poll',
        MESSAGE:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
      },
      COMPLETED: {
        HEAD: 'You already completed poll',
        MESSAGE:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
      },
      FINISHED: {
        HEAD: 'Sorry, this poll has ended',
        MESSAGE:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
      }
    }
  },
  BREADCRUMBS: {
    Polls: 'Polls',
    Create_new: 'Create',
    Users: 'Users',
    Results: 'Results',
    Edit: 'Edit',
    Recipients: 'Recipients',
    Upload_from_file: 'Upload from file'
  },
  VALIDATION: {
    REQUIRED: 'This field is required',
    EMAIL: 'Email format is wrong',
    NO_QUESTIONS: 'You need to add at least one question',
    PASSWORD_LENGTH: 'Password must be minimum 8 characters long',
    PASSWORD_ONE_LOWER: 'Password must contain at least one lower case letter',
    PASSWORD_ONE_CAPITAL: 'Password must contain at least one capital letter',
    PASSWORD_ONE_NUMBER: 'Password must contain at least one number',
    PASSWORD_ONE_SPECIAL: 'Password must contain at least one special character',
    REPEATED_PASSWORD: "Repeat password doesn't match Password"
  }
}
