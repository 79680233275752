import {
  RECIPIENT_CREATE_FAIL,
  RECIPIENT_CREATE_REQUEST,
  RECIPIENT_CREATE_RESET,
  RECIPIENT_CREATE_SUCCESS,
  RECIPIENT_DELETE_FAIL,
  RECIPIENT_DELETE_REQUEST,
  RECIPIENT_DELETE_RESET,
  RECIPIENT_DELETE_SUCCESS,
  RECIPIENT_GET_ALL_FAIL,
  RECIPIENT_GET_ALL_REQUEST,
  RECIPIENT_GET_ALL_SUCCESS,
  RECIPIENT_GET_ONE_FAIL,
  RECIPIENT_GET_ONE_REQUEST,
  RECIPIENT_GET_ONE_RESET,
  RECIPIENT_GET_ONE_SUCCESS,
  RECIPIENT_UPDATE_FAIL,
  RECIPIENT_UPDATE_REQUEST,
  RECIPIENT_UPDATE_RESET,
  RECIPIENT_UPDATE_SUCCESS,
  RECIPIENT_UPLOAD_REQUEST,
  RECIPIENT_UPLOAD_RESET,
  RECIPIENT_UPLOAD_SUCCESS
} from '../constants/recipientConstants'

export const recipientGetAllReducer = (state = {}, action) => {
  let error = ''
  switch (action.type) {
    case RECIPIENT_GET_ALL_REQUEST:
      return { loading: true }
    case RECIPIENT_GET_ALL_SUCCESS:
      return {
        loading: false,
        recipients: action.payload.recipients,
        count: action.payload.count
      }
    case RECIPIENT_GET_ALL_FAIL:
      error = action.payload
      return { loading: false, error }
    default:
      return state
  }
}

export const recipientDeleteReducer = (state = {}, action) => {
  let error = ''
  switch (action.type) {
    case RECIPIENT_DELETE_REQUEST:
      return { loading: true }
    case RECIPIENT_DELETE_SUCCESS:
      return { loading: false, success: action.payload }
    case RECIPIENT_DELETE_FAIL:
      switch (action.payload) {
        case 'RECIPIENT_HAS_SUBMITTED_POLL':
          error = 'GRADUATES.RECIPIENT_HAS_SUBMITTED_POLL'
          break
        default:
          error = 'GRADUATES.SOMETHING_WENT_WRONG'
      }
      return { loading: false, error }
    case RECIPIENT_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const recipientCreateReducer = (state = {}, action) => {
  let error = ''
  switch (action.type) {
    case RECIPIENT_CREATE_REQUEST:
      return { loading: true }
    case RECIPIENT_CREATE_SUCCESS:
      return { loading: false, success: true }
    case RECIPIENT_CREATE_FAIL:
      switch (action.payload) {
        case 'RECIPIENT_ALREADY_EXIST':
          error = 'GRADUATES.RECIPIENT_ALREADY_EXIST'
          break
        default:
          error = 'GRADUATES.SOMETHING_WENT_WRONG'
      }
      return { loading: false, error }
    case RECIPIENT_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const recipientUpdateReducer = (state = {}, action) => {
  let error = ''
  switch (action.type) {
    case RECIPIENT_UPDATE_REQUEST:
      return { loading: true }
    case RECIPIENT_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        recipients: action.payload.recipients
      }
    case RECIPIENT_UPDATE_FAIL:
      switch (action.payload) {
        case 'RECIPIENT_ALREADY_EXIST':
          error = 'GRADUATES.RECIPIENT_ALREADY_EXIST'
          break
        default:
          error = 'GRADUATES.SOMETHING_WENT_WRONG'
      }
      return { loading: false, error }
    case RECIPIENT_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const recipientGetOneReducer = (state = {}, action) => {
  let error = ''
  switch (action.type) {
    case RECIPIENT_GET_ONE_REQUEST:
      return { loading: true }
    case RECIPIENT_GET_ONE_SUCCESS:
      return { loading: false, success: true, recipient: action.payload }
    case RECIPIENT_GET_ONE_FAIL:
      error = action.payload
      return { loading: false, error }
    case RECIPIENT_GET_ONE_RESET:
      return {}
    default:
      return state
  }
}

export const recipientUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case RECIPIENT_UPLOAD_REQUEST:
      return { loading: true }
    case RECIPIENT_UPLOAD_SUCCESS:
      return { loading: false, response: action.payload }
    case RECIPIENT_UPLOAD_RESET:
      return {}
    default:
      return state
  }
}
