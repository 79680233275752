import { omit } from 'lodash'
import { emailValidation, isRequired } from './index'

export const validation = (inputs) => {
  let errors = {}
  for (const [key, value] of Object.entries(inputs)) {
    switch (key) {
      case 'firstName':
      case 'lastName':
      case 'graduateField':
        errors[key] = isRequired(value)
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
      case 'email':
        errors[key] = isRequired(value) || emailValidation(value)
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
      case 'graduateYear':
        errors[key] = isRequired(typeof value === 'object' ? value?.value : value)
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
    }
  }
  return errors
}
