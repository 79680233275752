import { XMarkIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'

const PositionsPopupModel = ({ onConfirm, positions, type }) => {
  const { t } = useTranslation()
  return (
    <div>
      <div className='fixed w-full h-full bg-white z-[52] left-0 top-0 bg-opacity-80'></div>
      <div className='rounded-lg shadow fixed bg-white z-[53] w-full max-w-[300px] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]'>
        <button
          onClick={onConfirm}
          type='button'
          className='absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center'
        >
          <XMarkIcon className='w-5 h-5' />
        </button>
        <div className='p-6 text-left'>
          <div className='mb-3'>
            <div className='flex flex-col gap-3'>
              {type === 'work' && (
                <div>
                  <h1 className='text-lg font-semibold text-gray-900 mb-2'>
                    {t('GRADUATES.WORK')}
                  </h1>
                  {positions?.work?.map((position, index) => {
                    return (
                      <div className='flex items-center gap-2 justify-between' key={index}>
                        <p className='text-gray-500'>
                          {' '}
                          {position.company}
                          {position.position && `, ${position.position}`}
                        </p>
                        <p className='text-gray-500'>
                          (
                          {position.startWorkDate && new Date(position.startWorkDate).getFullYear()}{' '}
                          -{' '}
                          {position.endWorkDate
                            ? new Date(position.endWorkDate).getFullYear()
                            : 'Trenutno'}
                          )
                        </p>
                      </div>
                    )
                  })}
                </div>
              )}
              {type === 'education' && (
                <div>
                  <h1 className='text-lg font-semibold text-gray-900 mb-2'>
                    {t('GRADUATES.EDUCATION')}
                  </h1>
                  {positions?.education?.map((position, index) => {
                    return (
                      <div className='flex items-center gap-2 justify-between' key={index}>
                        <p className='text-gray-500'>
                          {' '}
                          {position.institution}
                          {', '}
                          {position.major && position.major}
                        </p>
                        <p className='text-gray-500'>
                          (
                          {position.startEducationDate &&
                            new Date(position.startEducationDate).getFullYear()}{' '}
                          -{' '}
                          {position.endEducationDate
                            ? new Date(position.endEducationDate).getFullYear()
                            : 'Trenutno'}
                          )
                        </p>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
          <button
            onClick={onConfirm}
            type='button'
            className='text-white w-full bg-indigo-600 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2'
          >
            {t('MODAL.CLOSE')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default PositionsPopupModel
