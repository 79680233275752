import i18next from 'i18next'
import { Suspense } from 'react'
import { initReactI18next } from 'react-i18next'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { en, hr } from './helpers/translations'
import { AuthLayout, DashboardLayout } from './layouts'
import SignIn from './pages/Auth/SignIn'
import RecipientsForm from './pages/Dashboard/Recipients/RecipientForm'
import RecipientsList from './pages/Dashboard/Recipients/RecipientsList'
import RecipientsUploadFromFile from './pages/Dashboard/Recipients/RecipientsUploadFromFile'
import UsersForm from './pages/Dashboard/Users/UserForm'
import UsersList from './pages/Dashboard/Users/UsersList'
i18next.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    hr: { translation: hr }
  },
  lng: 'hr',
  fallBackLng: 'hr',
  interpolation: { escapeValue: false }
})

const App = () => {
  return (
    <div className=''>
      <Suspense fallback='Loading...'>
        <BrowserRouter>
          <Routes>
            <Route element={<DashboardLayout />}>
              <Route path='/graduates' element={<RecipientsList />} />
              <Route path='/graduates/create-new' element={<RecipientsForm />} />
              <Route path='/graduates/upload-from-file' element={<RecipientsUploadFromFile />} />
              <Route path='/graduates/edit/:recipientId' element={<RecipientsForm />} />
              <Route path='/users' element={<UsersList />} />
              <Route path='/users/create-new' element={<UsersForm />} />
              <Route path='/users/edit/:userId' element={<UsersForm />} />
            </Route>
            <Route element={<AuthLayout />}>
              <Route path='/signin' element={<SignIn />} />
            </Route>
            <Route path='*' element={<Navigate to='/graduates' replace />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
  )
}

export default App
