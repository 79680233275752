import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Modal from '../../../components/Modal'
import WarningModal from '../../../components/WarningModal'
import {
  deleteRecipient,
  getAll,
  massUpdateRecipients
} from '../../../redux/actions/recipentsActions'
import { getAll as getAllTags } from '../../../redux/actions/tagsActions'
import { RECIPIENT_DELETE_RESET } from '../../../redux/constants/recipientConstants'
import { RecipientsTable } from './components'
export const RecipientsList = () => {
  const [isModal, setIsModal] = useState(false)
  const [recipientId, setRecipientId] = useState(null)
  const { recipientDelete } = useSelector((state) => state)
  const { success: successDelete } = recipientDelete
  const [recipientQuery, setRecipientQuery] = useState('')
  const [workEducationQuery, setWorkEducationQuery] = useState('')
  const [displayTags, setDisplayTags] = useState([])
  const [offset, setOffset] = useState(0)
  const [message, setMessage] = useState('')
  const [isWarningModal, setIsWarningModal] = useState(false)
  const [updateParams, setUpdateParams] = useState({
    userQuery: '',
    positionsQuery: '',
    action: '',
    tagsQuery: [],
    tags: []
  })
  const [isDispatched, setIsDispatched] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch({ type: RECIPIENT_DELETE_RESET })
    dispatch(getAllTags(''))
  }, [dispatch])

  useEffect(() => {
    dispatch(
      getAll(offset, updateParams.userQuery, displayTags, updateParams.positionsQuery, 'true')
    )
  }, [dispatch, offset, updateParams.userQuery, updateParams.positionsQuery, displayTags])

  const handleQuerying = (e, type) => {
    switch (type) {
      case 'user':
        setRecipientQuery(e.target.value)
        setUpdateParams({
          ...updateParams,
          userQuery: e.target.value
        })
        setOffset(0)
        break
      case 'work':
        setWorkEducationQuery(e.target.value)
        setUpdateParams({
          ...updateParams,
          positionsQuery: e.target.value
        })
        setOffset(0)
        break
      default:
        break
    }
  }

  const handleAction = (action) => {
    if (updateParams.tags.length === 0) {
      setIsWarningModal(true)
      return
    }
    switch (action) {
      case 'add':
        setIsModal(true)
        setMessage('GRADUATES.ADD_TAGS_CONFIRMATION')
        setUpdateParams({
          ...updateParams,
          action: 'add'
        })
        break
      case 'remove':
        setIsModal(true)
        setMessage('GRADUATES.REMOVE_TAGS_CONFIRMATION')
        setUpdateParams({
          ...updateParams,
          action: 'remove'
        })
        break
      default:
        break
    }
  }

  const onConfirm = () => {
    switch (message) {
      case 'GRADUATES.DELETE_MESSAGE':
        dispatch(deleteRecipient(recipientId))
        setIsModal(false)
        setIsDispatched(true)
        break
      case 'GRADUATES.ADD_TAGS_CONFIRMATION':
        dispatch(massUpdateRecipients(updateParams))
        setIsModal(false)
        setIsDispatched(true)
        break
      case 'GRADUATES.REMOVE_TAGS_CONFIRMATION':
        dispatch(massUpdateRecipients(updateParams))
        setIsModal(false)
        setIsDispatched(true)
        break
      default:
        break
    }
  }

  const filterByNewTag = (tags) => {
    if (String(tags).startsWith('c') && !displayTags.includes(tags.slice(2))) {
      setDisplayTags((prevTags) => [...prevTags, String(tags).slice(2)])
    }
  }

  const onOpenModal = (id) => {
    setIsModal(true)
    setMessage('GRADUATES.DELETE_MESSAGE')
    setRecipientId(id)
  }

  const checkRemovedTags = (tags) => {
    const updatedDisplayTags = displayTags.filter(
      (tag) => !tags.map((tag) => tag.name).includes(tag)
    )
    if (updatedDisplayTags.length === 0) {
      setUpdateParams({
        ...updateParams,
        tagsQuery: []
      })
    }
    setDisplayTags(updatedDisplayTags)
  }

  useEffect(() => {
    switch (displayTags.length) {
      case 0:
        setUpdateParams({
          ...updateParams,
          tagsQuery: []
        })
        break
      default:
        setUpdateParams({
          ...updateParams,
          tagsQuery: displayTags
        })
        break
    }
  }, [displayTags])

  useEffect(() => {
    if (successDelete) {
      dispatch(
        getAll(offset, updateParams.userQuery, displayTags, updateParams.positionsQuery, 'true')
      )
    }
  }, [successDelete])

  useEffect(() => {
    if (isDispatched) {
      setIsDispatched(false)
      if (updateParams.action === 'remove') {
        checkRemovedTags(updateParams.tags)
      }
    }
  }, [isDispatched, updateParams.action])

  return (
    <div className='w-full self-start'>
      {isModal && (
        <Modal onConfirm={onConfirm} onCancel={() => setIsModal(false)} message={t(message)} />
      )}
      {isWarningModal && (
        <WarningModal
          onConfirm={() => setIsWarningModal(false)}
          onCancel={() => setIsWarningModal(false)}
          message={t('GRADUATES.WARNING_MESSAGE')}
        />
      )}
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-[28px] font-semibold text-gray-900'>{t('GRADUATES.GRADUATES')}</h1>
        </div>
      </div>
      <div className='flex justify-between items-center gap-4 flex-wrap mt-3'>
        <div className='flex justify-between items-end w-full flex-wrap gap-3'>
          <div className='flex items-center gap-2'>
            <div className='w-full'>
              <div className='mt-1 relative'>
                <div className='absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none'>
                  <MagnifyingGlassIcon className='h-4 w-4 text-gray-400' aria-hidden='true' />
                </div>
                <input
                  onChange={(e) => handleQuerying(e, 'user')}
                  placeholder={t('GRADUATES.SEARCH_RECIPIENTS')}
                  type='text'
                  className='block w-full appearance-none rounded-md border border-gray-300 px-8 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm'
                  value={recipientQuery}
                />
              </div>
            </div>
            <div className='w-full'>
              <div className='mt-1 relative'>
                <div className='absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none'>
                  <MagnifyingGlassIcon className='h-4 w-4 text-gray-400' aria-hidden='true' />
                </div>
                <input
                  onChange={(e) => handleQuerying(e, 'work')}
                  placeholder={t('GRADUATES.SEARCH_JOB_EDUCATION')}
                  type='text'
                  className='block w-full appearance-none rounded-md border border-gray-300 px-8 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm'
                  value={workEducationQuery}
                />
              </div>
            </div>
          </div>
          <div className='flex items-center gap-2'>
            <Link
              to='/graduates/create-new'
              className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
            >
              {t('GRADUATES.ADD_NEW')}
            </Link>
            <Link
              to='/graduates/upload-from-file'
              className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
            >
              {t('GRADUATES.ADD_NEW_FROM_FILE')}
            </Link>
          </div>
        </div>
      </div>
      <RecipientsTable
        offset={offset}
        setOffset={setOffset}
        onOpenModal={onOpenModal}
        filterByNewTag={filterByNewTag}
      />
    </div>
  )
}

export default RecipientsList
