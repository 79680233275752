import axios from 'axios'
import mem from 'mem'
import { API_BASE } from './api.service'

const DATA_KEY = 'S_DATA'

export const create = ({ accessToken, refreshToken }) => {
  const userData = JSON.stringify({ accessToken, refreshToken })
  localStorage.setItem(DATA_KEY, userData)
}

export const update = (accessToken) => {
  const { refreshToken } = JSON.parse(localStorage.getItem(DATA_KEY))
  const userData = JSON.stringify({ accessToken, refreshToken })
  localStorage.setItem(DATA_KEY, userData)
}

export const getAccessToken = () => {
  try {
    const userData = JSON.parse(localStorage.getItem(DATA_KEY))
    return userData.accessToken
  } catch (err) {
    return null
  }
}

export const remove = () => localStorage.removeItem(DATA_KEY)

export const getRefreshToken = () => {
  try {
    const userData = JSON.parse(localStorage.getItem(DATA_KEY))
    return userData.refreshToken
  } catch (ex) {
    return null
  }
}

const refreshToken = async () => {
  try {
    const refreshToken = getRefreshToken()
    const response = await axios.get(API_BASE + '/auth/refresh', {
      headers: {
        Authorization: 'Bearer ' + refreshToken
      }
    })
    const { accessToken } = response.data
    update(accessToken)
    const newToken = {
      accessToken
    }
    return newToken
  } catch (error) {
    const newToken = {
      error
    }
    return newToken
  }
}

const maxAge = 10000

export const memoizedRefreshToken = mem(refreshToken, {
  maxAge
})
